import { ITimeValue } from '@/overlay/types/control';
import { TimeType } from '@/overlay/types/template';

/** 倒计时 */
export const timeDown = (timeValue: ITimeValue) => {
  switch (timeValue.format) {
    case 'ss':
      if (timeValue.seconds !== 0) {
        timeValue.seconds--;
      }
      break;
    case 'mm:ss':
      if (timeValue.minutes === 0 && timeValue.seconds === 0) return;
      if (timeValue.seconds === 0) {
        timeValue.seconds = 59;
        timeValue.minutes--;
      } else {
        timeValue.seconds--;
      }
      break;
    default:
      if (timeValue.hours === 0 && timeValue.minutes === 0 && timeValue.seconds === 0) return;
      if (timeValue.seconds === 0) {
        timeValue.seconds = 59;
        if (timeValue.minutes === 0) {
          timeValue.minutes = 59;
          timeValue.hours--;
        } else {
          timeValue.minutes--;
        }
      } else {
        timeValue.seconds--;
      }
      break;
  }
};

/** 正计时 */
export const timeUp = (timeValue: ITimeValue) => {
  switch (timeValue.format) {
    case 'ss':
      timeValue.seconds++;
      break;
    case 'mm:ss':
      if (timeValue.seconds === 59) {
        timeValue.seconds = 0;
        timeValue.minutes++;
      } else {
        timeValue.seconds++;
      }
      break;
    default:
      if (timeValue.seconds === 59) {
        timeValue.seconds = 0;
        if (timeValue.minutes === 59) {
          timeValue.hours++;
          timeValue.minutes = 0;
        } else {
          timeValue.minutes++;
        }
      } else {
        timeValue.seconds++;
      }
      break;
  }
};

/**
 * 根据 playTime 同步时间
 * value 为 ITimeValue 对象的 JSON 字符串
 */
export const syncTime = (value: string): string => {
  const timeValue: ITimeValue = JSON.parse(value);
  if (
    timeValue.playTime &&
    (timeValue.type === TimeType.Down || timeValue.type === TimeType.Up) &&
    timeValue.play
  ) {
    const duration = (new Date().getTime() - timeValue.playTime) / 1000;
    const currentDuration = timeValue.hours * 3600 + timeValue.minutes * 60 + timeValue.seconds;
    let finalDuration: number;
    if (timeValue.type === TimeType.Down) {
      const differ = currentDuration - duration;
      finalDuration = differ >= 0 ? differ : 0;
    } else {
      finalDuration = currentDuration + duration;
    }
    switch (timeValue.format) {
      case 'ss':
        timeValue.hours = 0;
        timeValue.minutes = 0;
        timeValue.seconds = Math.floor(finalDuration);
        break;
      case 'mm:ss':
        timeValue.hours = 0;
        timeValue.minutes = Math.floor(finalDuration / 60);
        timeValue.seconds = Math.floor(finalDuration % 60);
        break;
      default:
        timeValue.hours = Math.floor(finalDuration / 3600);
        timeValue.minutes = Math.floor((finalDuration % 3600) / 60);
        timeValue.seconds = Math.floor(finalDuration % 60);
        break;
    }
    return JSON.stringify(timeValue);
  }
  return '';
};
